import type { RouteLocationNormalizedLoaded } from "vue-router";
import type { Properties } from "posthog-js";

import useAnalytics from "@mono/ui/composables/useAnalytics";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mainAnalyticsEventNames = [
  "signup_flow:create_account_button_click",
  "signup_flow:register_success",
  "signup_flow:verify_otp_success",
  "signin_flow:login_button_click",
  "dashboard:create_org_button_click",
  "dashboard:card_create_org_button_click",
  "create_org_form:create_org_success",
  "create_org_form:create_org_failed",
  "subscription_flow:manage_button_click",
  "subscription_flow:manage_subscription_button_click",
  "subscription_flow:add_subscription_plan_button_click",
  // AddPaymentMethodBtnClick = "subcription_flow:add_payment_method_button_click",
  // EnabledBankTransferPaymentMethod = "subcription_flow:enable_bank_transfer_button_click",
  // EnablePaymentMethod = "subcription_flow:select_visa_payment_method_button_click",
  "subscription_flow:plan_selected",
  "subscription_flow:submit_subscription_success",
  "subscription_flow:submit_subscription_failed",
  "subscription_flow:cancel_subscription_success",
  "subscription_flow:cancel_subscription_failed",
] as const;
type MainAnalyticsEventNames = (typeof mainAnalyticsEventNames)[number];
export type MainAnalyticsEvent = {
  name: MainAnalyticsEventNames;
  properties?: Properties;
};

export default function useAnalyticsMain() {
  const analytics = useAnalytics();
  const config = useRuntimeConfig();

  const initPosthog = () => {
    analytics.initPostHog(config.public.posthogPublicKey);
  };

  const track = ({ name, properties }: MainAnalyticsEvent) => {
    analytics.track({ name, properties });
  };

  const trackPageView = () => {
    const route = useRoute();
    analytics.trackPageView(route as RouteLocationNormalizedLoaded);
  };
  const eventTrackerInstance = () => {
    return analytics.posthogInstance();
  };

  const getUserDistinctId = () => {
    return analytics.getUserDistinctId();
  };

  const identifyUser = (identifier: string, properties?: Properties) => {
    analytics.identifyUser(identifier, properties);
  };

  const resetOnLogout = () => {
    analytics.resetOnLogout();
  };
  return {
    initPosthog,
    track,
    trackPageView,
    eventTrackerInstance,
    getUserDistinctId,
    identifyUser,
    resetOnLogout,
  };
}
