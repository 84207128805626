import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";

export const setZodI18nMap = (
  options: Parameters<typeof makeZodI18nMap>[0]
) => {
  z.setErrorMap(makeZodI18nMap(options));
};

export * from "zod";
