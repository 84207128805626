import { useStorage } from "@vueuse/core";
import { useTheme } from "vuetify";
import { watch } from "vue";

const getMediaPreference = () => {
  const hasDarkPreference = window.matchMedia(
    "(prefers-color-scheme: dark)"
  ).matches;

  return hasDarkPreference ? "dark" : "light";
};

export const useThemeStorage = () => {
  const vuetifyTheme = useTheme();

  const theme = useStorage<{
    name: "light" | "dark" | "system";
  }>("theme", {
    name: "system",
  });

  const watchTheme = () => {
    watch(
      () => theme.value.name,
      (val) => {
        if (val === "system") {
          vuetifyTheme.global.name.value = getMediaPreference();
          return;
        }

        vuetifyTheme.global.name.value = val;
      },
      { immediate: true }
    );
  };

  return {
    theme: vuetifyTheme.global.name,
    storageTheme: theme,
    watchTheme,
  };
};
