import type {
  EmailOtpVerificationArgsSchema,
  EmailRequestVerifyArgsSchema,
  PasswordResetSubmitArgsSchema,
  UserLoginArgsSchema,
  UserRegistrationArgsSchema,
} from "@mono/validation/lib/Auth";
import type { InviteAcceptActionArgsSchema } from "@mono/validation/lib/Organization";
import type { APIOutputs } from "@/composables/useTrpcClient";

type UserDetails = APIOutputs["user"]["details"]["data"];
type UserRole =
  APIOutputs["organizations"]["getOrganizationACL"]["data"][number];

export const useAuthStore = defineStore("auth", () => {
  const { crisp } = useCrisp();
  const { setTokens, isLoggedIn, resetTokens } = useAuthStorage();
  const organizationStore = useOrganizationStore();
  const analytics = useAnalyticsMain();
  const router = useRouter();

  const userId = ref<string>();
  const userDetails = ref<UserDetails>();
  const userRoles = ref<UserRole[]>([]);

  const getUserOrgPermissions = (orgId: string) => {
    return Object.assign(
      {},
      toRaw(userRoles.value).find((role) => role.orgId === orgId),
      organizationStore.orgFlags
    );
  };

  const resetUser = () => {
    crisp.clearSession();
    resetTokens();
    userId.value = undefined;
    userDetails.value = undefined;
    userRoles.value = [];
  };

  const fetchUserRoles = async () => {
    const { trpcClient } = useTrpcClient();
    try {
      const result = await trpcClient.organizations.getOrganizationACL.query();

      if (result.data === undefined) {
        return;
      }

      userRoles.value = result.data;
      return result.data;
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const fetchUser = async (): Promise<UserDetails | undefined> => {
    const { trpcClient } = useTrpcClient();
    try {
      const result = await trpcClient.user.details.query();

      if (result.data === undefined) {
        return;
      }

      userDetails.value = result.data as UserDetails;

      await fetchUserRoles();

      return result.data as UserDetails;
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const getOrgAccessToken = async (orgId: string) => {
    const { trpcClient } = useTrpcClient();
    try {
      const { token } = await trpcClient.auth.getOrgAccessToken.query(
        {
          orgId,
        },
        {
          context: {
            skipBatch: true,
          },
        }
      );

      setTokens({ token: null, refreshToken: null, orgToken: token, orgId });

      return { success: true };
    } catch (error) {
      console.error("Error getting org access token:", error);
      return { success: false };
    }
  };

  const logout = () => {
    resetUser();
    organizationStore.resetOrganization();
    analytics.resetOnLogout();

    router.replace({ name: "index" });
  };

  const login = async ({
    email,
    password,
    rememberMe,
  }: UserLoginArgsSchema) => {
    const { trpcClient } = useTrpcClient();
    try {
      const { token, refreshToken } = await trpcClient.auth.loginUser.mutate(
        {
          email,
          password,
          rememberMe,
        },
        {
          context: {
            skipBatch: true,
          },
        }
      );

      setTokens({ token, refreshToken, orgToken: null });

      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  // Debounce the refreshTokens function to prevent multiple calls
  const refreshTokens = async (orgId?: string) => {
    const { trpcClient } = useTrpcClient();
    const { authStorage } = useAuthStorage();

    const refreshToken = authStorage.value.refreshToken;

    try {
      if (refreshToken == null) {
        throw new Error("No refresh token found");
      }

      const tokens = await trpcClient.auth.refreshTokens.mutate(
        {
          refreshToken,
          orgId,
        },
        {
          context: {
            skipBatch: true,
          },
        }
      );

      setTokens({
        token: tokens.token,
        refreshToken: tokens.refreshToken,
        orgToken: tokens.orgToken,
        orgId,
      });

      // Fetch user details after refreshing tokens to ensure the user details are up to date
      // this handle the case when the user first open the app with a stale token
      await init();

      return { success: true };
    } catch {
      logout();
      return { success: false };
    }
  };

  const register = async (args: UserRegistrationArgsSchema) => {
    const { trpcClient } = useTrpcClient();
    try {
      const result = await trpcClient.auth.registerUser.mutate(args);

      return result;
    } catch {
      return { success: false };
    }
  };

  const requestVerify = async (args: EmailRequestVerifyArgsSchema) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.verifyEmailRequest.mutate(args);

      const { isVerified, isRegistered } = result;

      return { isVerified, isRegistered, hasError: false, success: true };
    } catch {
      return {
        success: false,
        hasError: true,
        isVerified: false,
        isRegistered: false,
      };
    }
  };

  const verify = async (args: { linkUUID: string }) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.verifyEmailLink.mutate(args);

      const { success } = result;
      const hasVerified = success || false;
      return { hasVerified, success: true };
    } catch {
      return { hasVerified: false, success: false };
    }
  };

  const otpVerify = async (args: EmailOtpVerificationArgsSchema) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.verifyEmailOtp.mutate(args);

      const { success, attemptsRemaining } = result;
      return { hasVerified: success, attemptsRemaining, success: true };
    } catch {
      return { success: false };
    }
  };

  const requestPasswordReset = async (args: { email: string }) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.requestPasswordReset.mutate(args);

      return { success: result.success, hasError: false };
    } catch {
      return { success: false, hasError: true };
    }
  };

  const passwordReset = async (args: PasswordResetSubmitArgsSchema) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.auth.submitPasswordReset.mutate(args);

      const { success } = result;
      return { hasVerified: success, success: true };
    } catch {
      return { success: false };
    }
  };

  const inviteAccept = async (args: InviteAcceptActionArgsSchema) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result =
        await trpcClient.organizationMembers.inviteAccept.mutate(args);

      const { orgId, success } = result;
      return { orgId, hasError: !success, success: true };
    } catch {
      return { hasError: true, success: false };
    }
  };

  const init = async () => {
    if (isLoggedIn.value && userId.value === undefined) {
      const userDetails = await fetchUser();

      if (userDetails) {
        userId.value = userDetails.id;

        // identify logged in user for analytics
        analytics.identifyUser(userDetails.analyticsId);

        crisp.setUser({
          email: userDetails.email,
          crispSignedEmail: userDetails.crispSignEmail,
        });
      }
    }
  };

  return {
    userId,
    userDetails,
    userRoles,

    fetchUser,
    fetchUserRoles,
    getUserOrgPermissions,

    init,

    login,
    register,
    logout,
    requestVerify,
    verify,
    otpVerify,
    requestPasswordReset,
    passwordReset,
    inviteAccept,
    getOrgAccessToken,
    refreshTokens,
  };
});
