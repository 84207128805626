<script setup lang="ts">
import { setZodI18nMap } from "@mono/validation";
import { useWindowSize } from "@vueuse/core";
import { useCrisp } from "@mono/ui/composables/useCrisp";
import type { Locale } from "@mono/constants/lib/locale";

const config = useRuntimeConfig();
const { watchTheme } = useThemeStorage();
const { t, locale } = useI18n();

const dir = computed(() => (locale.value === "ar" ? "rtl" : "ltr"));

const { initPosthog, trackPageView } = useAnalyticsMain();
initPosthog();
const { crisp } = useCrisp();
crisp.setup({
  isProd: config.public.API_TARGET === "production",
  locale: locale.value as Locale,
});
const { width } = useWindowSize();
watch(
  width,
  (v) => {
    if (v < 768) {
      crisp.hide();
    } else {
      crisp.show();
    }
  },
  { immediate: true }
);

// Set the Zod i18n map binded to the i18n instance
setZodI18nMap({ t: t as any });

watchTheme();

const robotsMeta =
  config.public.API_TARGET === "production"
    ? []
    : [{ name: "robots", content: "noindex, nofollow" }];

const { setTitle } = usePageTitle();

useHead({
  title: t("cloud-accounting"),
  titleTemplate: (title) => {
    setTitle(title);
    return `${t("mezan")} | ${title || t("cloud-accounting")}`;
  },
  meta: [
    ...robotsMeta,
    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
  ],
});

trackPageView();
</script>

<template>
  <Html
    :lang="locale"
    :dir="dir"
  >
    <NuxtLayout :class="locale === 'en' ? 'font-en' : 'font-ar'">
      <NuxtPage :key="locale" />
    </NuxtLayout>
  </Html>
</template>
