export default defineNuxtRouteMiddleware((route) => {
  // !solves an edge case where route.name isn't found
  if (!route?.name) return;
  const isInviteRoute = route.name!.toString().startsWith("auth-invite-uuid");
  if (isInviteRoute) return;
  const isInDashboard = route.name!.toString().startsWith("dashboard");
  const { isLoggedIn } = useAuthStorage();
  if (isLoggedIn.value === true && isInDashboard === false) {
    return navigateTo({ name: "dashboard" });
  }
  if (isLoggedIn.value === false && isInDashboard === true) {
    return navigateTo({ name: "index" });
  }
});
