import type { PlansFlagsSchema } from "@mono/validation/lib/Plans";
import type { APIOutputs } from "@/composables/useTrpcClient";

export type OrganizationDetails = APIOutputs["organizations"]["getOne"]["data"];

export type OrganizationMember =
  APIOutputs["organizations"]["getMetadata"]["data"]["organizationMembers"][number];

export type OrganizationAccount =
  APIOutputs["organizations"]["getMetadata"]["data"]["accounts"][number];

export type OrganizationReceiptPaymentMethod =
  APIOutputs["organizations"]["getMetadata"]["data"]["receiptPaymentMethods"][number];

export type OrganizationReportingDimension =
  APIOutputs["organizations"]["getMetadata"]["data"]["reportingDimensions"][number];

export type OrganizationLockedPeriod =
  APIOutputs["organizations"]["getMetadata"]["data"]["lockedPeriods"][number];

export type OrganizationDefaultSetting =
  APIOutputs["organizations"]["getDefaultSettings"]["data"];

export const useOrganizationStore = defineStore("organization", () => {
  const authStore = useAuthStore();
  const { setCurrency, clearCurrency } = useCurrency();

  // state
  const orgId = ref("");
  const orgDetails = ref<OrganizationDetails | undefined>(undefined);
  const orgAccounts = ref<OrganizationAccount[]>([]);
  const orgMembers = ref<OrganizationMember[]>([]);
  const orgReceiptPaymentMethods = ref<OrganizationReceiptPaymentMethod[]>([]);
  const orgReportingDimensions = ref<OrganizationReportingDimension[]>([]);
  const orgLockedPeriods = ref<OrganizationLockedPeriod[]>([]);
  const defaultSettings = ref<OrganizationDefaultSetting>({
    fixedAssetsSettings: null,
    productsSettings: null,
    salesSettings: null,
    purchasingSettings: null,
    transactionsSettings: null,
  });

  const resetOrganization = () => {
    orgId.value = "";
    orgDetails.value = undefined;
    orgAccounts.value = [];
    orgMembers.value = [];
    orgReceiptPaymentMethods.value = [];
    orgReportingDimensions.value = [];
    orgLockedPeriods.value = [];
    defaultSettings.value = {
      fixedAssetsSettings: null,
      productsSettings: null,
      salesSettings: null,
      purchasingSettings: null,
      transactionsSettings: null,
    };
    clearCurrency();
  };

  const orgFlags = computed<PlansFlagsSchema>(
    () =>
      orgDetails.value?.subscriptionsHistory?.plans ?? {
        enableFixedAssetsModule: false,
        enableInventoryTracking: false,
        enableManualJournals: false,
        enableReportingDimensions: false,
      }
  );

  const hydratedAccounts = computed(() => {
    return orgAccounts.value.map((account) => {
      let compositeCode = account.type.toString();
      let accountLevel = 1;
      for (let level = 1; level <= 7; level++) {
        const codeLevelKey = `codeLevel${level}` as keyof typeof account;
        const codeLevel = account[codeLevelKey];

        if (codeLevel) {
          const padLength = level === 1 ? 1 : level === 7 ? 5 : 2;
          compositeCode += codeLevel.toString().padStart(padLength, "0");
        } else {
          accountLevel = level - 1;
          break;
        }
      }
      return {
        ...account,
        compositeCode,
        accountLevel,
      };
    });
  });

  const orgDefaultAccounts = computed(() => ({
    assetsAcquisition:
      defaultSettings.value?.fixedAssetsSettings
        ?.defaultAssetsAcquisitionAccountId,
    assetsDepreciation:
      defaultSettings.value?.fixedAssetsSettings?.defaultDepreciationAccountId,
    assetsAppreciation:
      defaultSettings.value?.fixedAssetsSettings?.defaultAppreciationAccountId,
    productsCogs: defaultSettings.value?.productsSettings?.defaultCogsAccountId,
    productsExpense:
      defaultSettings.value?.productsSettings?.defaultExpenseAccountId,
    productsRevenue:
      defaultSettings.value?.productsSettings?.defaultRevenueAccountId,
    stocktakeExpense:
      defaultSettings.value?.productsSettings?.defaultStocktakeExpenseAccountId,
    stocktakeRevenue:
      defaultSettings.value?.productsSettings?.defaultStocktakeRevenueAccountId,
    salesCash: defaultSettings.value?.salesSettings?.defaultCashAccountId,
    salesReceivables:
      defaultSettings.value?.salesSettings?.defaultReceivablesAccountId,
    purchasingCash:
      defaultSettings.value?.purchasingSettings?.defaultCashAccountId,
    purchasingPayables:
      defaultSettings.value?.purchasingSettings?.defaultPayablesAccountId,
  }));

  const fetchOrganizationDetails = async (id: string) => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.organizations.getOne.query({
        id,
      });

      orgId.value = result.data.id;
      orgDetails.value = result.data;
      setCurrency(result.data.currency);

      return result.data;
    } catch (error) {
      console.error("Error fetching organization details:", error);
    }
  };

  const fetchOrganizationMembers = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.organizationMembers.getAll.query({
        where: {
          acceptedAt: { _is_null: false },
        },
        orderBy: [
          {
            createdAt: "asc",
          },
        ],
      });

      orgMembers.value = result.data;
    } catch (error) {
      console.error("Error fetching organization members:", error);
    }
  };

  const fetchOrganizationAccounts = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.accounts.getAll.query({
        orderBy: [
          { type: "asc" },
          { codeLevel1: "asc" },
          { codeLevel2: "asc" },
          { codeLevel3: "asc" },
          { codeLevel4: "asc" },
          { codeLevel5: "asc" },
          { codeLevel6: "asc" },
          { codeLevel7: "asc" },
        ],
      });

      orgAccounts.value = result.data;

      return result.data;
    } catch (error) {
      console.error("Error fetching organization accounts:", error);
    }
  };

  const fetchOrganizationReceiptPaymentMethods = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.receiptPaymentMethods.getAll.query({
        orderBy: [
          {
            createdAt: "asc",
          },
        ],
      });

      orgReceiptPaymentMethods.value = result.data;
    } catch (error) {
      console.error(
        "Error fetching organization receipt payment methods:",
        error
      );
    }
  };

  const fetchReportingDimensions = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.reportingDimensions.getAll.query({});

      orgReportingDimensions.value = result.data;
    } catch (error) {
      console.error("Error fetching reporting dimensions:", error);
    }
  };

  const fetchOrganizationLockedPeriods = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.lockedPeriods.getAll.query({});

      orgLockedPeriods.value = result.data;
    } catch (error) {
      console.error("Error fetching organization locked periods:", error);
    }
  };

  const fetchOrganizationSettings = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const result = await trpcClient.organizations.getDefaultSettings.query(
        {}
      );

      defaultSettings.value = result.data;
    } catch (error) {
      console.error("Error fetching organization settings:", error);
    }
  };

  const fetchOrganizationMetadata = async () => {
    const { trpcClient } = useTrpcClient();

    try {
      const { data: organization } =
        await trpcClient.organizations.getMetadata.query();

      orgAccounts.value = organization.accounts;
      orgMembers.value = organization.organizationMembers;
      orgReceiptPaymentMethods.value = organization.receiptPaymentMethods;
      orgReportingDimensions.value = organization.reportingDimensions;
      orgLockedPeriods.value = organization.lockedPeriods;
      defaultSettings.value = {
        fixedAssetsSettings: organization.fixedAssetsSettings,
        productsSettings: organization.productsSettings,
        salesSettings: organization.salesSettings,
        purchasingSettings: organization.purchasingSettings,
        transactionsSettings: organization.transactionsSettings,
      };

      return organization;
    } catch (error) {
      console.error("Error fetching organization metadata:", error);
    }
  };

  const init = async (
    orgId: string
  ): Promise<{
    isInactiveOrg: boolean | null;
    isNetworkError: boolean;
    onboardingRedirect: boolean | null;
  }> => {
    try {
      resetOrganization();

      const [organization] = await Promise.all([
        fetchOrganizationDetails(orgId),
        authStore.getOrgAccessToken(orgId),
      ]);

      if (organization === undefined) {
        return {
          isInactiveOrg: null,
          isNetworkError: true,
          onboardingRedirect: null,
        };
      }

      const isActiveOrg = organization?.isActive === true;

      if (isActiveOrg) {
        await authStore.fetchUserRoles();
        const metadata = await fetchOrganizationMetadata();

        const onboardingRedirect =
          metadata?.accounts == null || metadata?.accounts.length === 0;

        return {
          isInactiveOrg: !isActiveOrg,
          isNetworkError: false,
          onboardingRedirect,
        };
      }

      return {
        isInactiveOrg: !isActiveOrg,
        isNetworkError: false,
        onboardingRedirect: null,
      };
    } catch (error) {
      console.error(error);
      return {
        isInactiveOrg: null,
        isNetworkError: true,
        onboardingRedirect: null,
      };
    }
  };

  return {
    // state
    orgId,
    orgDetails,
    orgAccounts,
    orgMembers,
    orgReceiptPaymentMethods,
    orgReportingDimensions,
    orgLockedPeriods,
    // computed
    hydratedAccounts,
    defaultSettings,
    orgDefaultAccounts,
    orgFlags,

    resetOrganization,
    fetchOrganizationAccounts,
    fetchReportingDimensions,
    fetchOrganizationDetails,
    fetchOrganizationMembers,
    fetchOrganizationReceiptPaymentMethods,
    fetchOrganizationLockedPeriods,
    fetchOrganizationSettings,
    init,
  };
});
