import { Crisp, ChatboxPosition, ChatboxColors } from "crisp-sdk-web";
import type { Locale } from "@mono/constants/lib/locale";

const WEBSITE_ID = "fb314596-dfce-4e7f-86ac-cfe49e72eb53";

const setup = ({ isProd, locale }: { isProd: boolean; locale: Locale }) => {
  Crisp.configure(WEBSITE_ID, {
    // autoload: false,
    locale,
    sessionMerge: true,
  });
  Crisp.setColorTheme(ChatboxColors.Green);
  if (!isProd) {
    Crisp.session.setSegments(["test"], false);
  }
  if (locale === "ar") {
    Crisp.setPosition(ChatboxPosition.Left);
  } else {
    Crisp.setPosition(ChatboxPosition.Right);
  }
};

const setUser = ({
  email,
  crispSignedEmail,
}: {
  email: string;
  crispSignedEmail: string;
}) => {
  Crisp.user.setEmail(email, crispSignedEmail);
};
const clearSession = () => {
  try {
    Crisp.session.reset();
  } catch (error) {
    console.debug(error);
  }
};

const simpleToggle = () => {
  if (Crisp.chat.isChatOpened()) {
    Crisp.chat.hide();
  } else {
    Crisp.chat.open();
  }
};
const chatToggle = () => {
  if (Crisp.chat.isVisible()) {
    simpleToggle();
  } else {
    Crisp.chat.show();
    simpleToggle();
  }
};

const hide = () => {
  Crisp.chat.hide();
  Crisp.chat.onChatClosed(() => {
    Crisp.chat.hide();
  });
};
const show = () => {
  Crisp.chat.show();
  Crisp.chat.onChatClosed(() => {
    Crisp.chat.show();
  });
};

export const useCrisp = () => {
  return {
    crisp: {
      setup,
      setUser,
      clearSession,
      chatToggle,
      hide,
      show,
    },
  };
};
