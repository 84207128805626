import { default as _91uuid_93ZrDXI0eeOCMeta } from "/vercel/path0/apps/main/pages/auth/email/verify/[uuid].vue?macro=true";
import { default as otpB7jAz4ETvCMeta } from "/vercel/path0/apps/main/pages/auth/email/verify/otp.vue?macro=true";
import { default as requestLIRS0QE8NqMeta } from "/vercel/path0/apps/main/pages/auth/email/verify/request.vue?macro=true";
import { default as _91uuid_93jRin05NlcQMeta } from "/vercel/path0/apps/main/pages/auth/invite/[uuid].vue?macro=true";
import { default as indexmH2SdOBKhjMeta } from "/vercel/path0/apps/main/pages/auth/password/reset/index.vue?macro=true";
import { default as _91uuid_93qNnNEEpYZTMeta } from "/vercel/path0/apps/main/pages/auth/password/reset/submit/[uuid].vue?macro=true";
import { default as changeemailA1f58kT6GZMeta } from "/vercel/path0/apps/main/pages/dashboard/account/changeemail.vue?macro=true";
import { default as changepassword5yNZka7H6rMeta } from "/vercel/path0/apps/main/pages/dashboard/account/changepassword.vue?macro=true";
import { default as editprofileGS4lx3avqiMeta } from "/vercel/path0/apps/main/pages/dashboard/account/editprofile.vue?macro=true";
import { default as indexDqLwLilSAmMeta } from "/vercel/path0/apps/main/pages/dashboard/account/index.vue?macro=true";
import { default as accountEhalYsnROyMeta } from "/vercel/path0/apps/main/pages/dashboard/account.vue?macro=true";
import { default as addZp8skxu2J8Meta } from "/vercel/path0/apps/main/pages/dashboard/billing/methods/add.vue?macro=true";
import { default as indexozvTZxoJRrMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/methods/index.vue?macro=true";
import { default as addhHzQGQrPdxMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/add.vue?macro=true";
import { default as removepqVX8icdGcMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/remove.vue?macro=true";
import { default as addsubscription0gIAVnsWgUMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addsubscription.vue?macro=true";
import { default as indexWxDgOYNohCMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/index.vue?macro=true";
import { default as index7mZcaPRdwLMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/index.vue?macro=true";
import { default as indexTHM0MiqjeOMeta } from "/vercel/path0/apps/main/pages/dashboard/index.vue?macro=true";
import { default as importGrGX5890kWMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/import.vue?macro=true";
import { default as indexHuQmqgGIVAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/index.vue?macro=true";
import { default as dimensionsCDQNiQXkxSMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions.vue?macro=true";
import { default as _91journalId_93W7i5aJisM0Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId].vue?macro=true";
import { default as indexO4HWn8H8QKMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/index.vue?macro=true";
import { default as customersUF3zhb5mGfMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers.vue?macro=true";
import { default as indexodMkvReCfsMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/index.vue?macro=true";
import { default as inventoryYK8wz8fS7pMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/inventory.vue?macro=true";
import { default as newCF8k1h3XX8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/new.vue?macro=true";
import { default as vendorsyb7olHCgjxMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors.vue?macro=true";
import { default as yeartoyearMhBJwPmFkdMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/yeartoyear.vue?macro=true";
import { default as _91receiptId_93X3qAhqqDXlMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/[receiptId].vue?macro=true";
import { default as indexYvjYsQO6guMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/index.vue?macro=true";
import { default as settingsBWd8anrbb8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/settings.vue?macro=true";
import { default as _91assetId_93fRytpssviQMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId].vue?macro=true";
import { default as indexo87uXVvfwAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/index.vue?macro=true";
import { default as settings9EPHMAU1iGMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/settings.vue?macro=true";
import { default as indexkqyW0Gj3KaMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/index.vue?macro=true";
import { default as categoriesXQVb4GPE8HMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories.vue?macro=true";
import { default as _91productId_93On6Oei9sjpMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId].vue?macro=true";
import { default as import1jHrIQPsObMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/import.vue?macro=true";
import { default as index8KIo3kzo9XMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/index.vue?macro=true";
import { default as settingsrL0nhkcFQiMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/settings.vue?macro=true";
import { default as _91stocktakeId_93y2GiOxvwSNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId].vue?macro=true";
import { default as indexFvqsAJesBgMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/index.vue?macro=true";
import { default as _91storeId_93hBJbUE8oETMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId].vue?macro=true";
import { default as indexuxw4HnIr1QMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/index.vue?macro=true";
import { default as _91transferId_93JfOQOHByZ8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/[transferId].vue?macro=true";
import { default as indexwkU20oaki8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/index.vue?macro=true";
import { default as index51dwkgJLL6Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/onboarding/index.vue?macro=true";
import { default as detailsR3Gh5ppEEDMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/details.vue?macro=true";
import { default as lockedperiodsVVazmEgMXUMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/lockedperiods.vue?macro=true";
import { default as membersq7Ht4lsbEeMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/members.vue?macro=true";
import { default as indexg5nfL1miGTMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/index.vue?macro=true";
import { default as settingsuflVjndcXrMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/settings.vue?macro=true";
import { default as receiptpaymentmethodsS4wID02QW8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/receiptpaymentmethods.vue?macro=true";
import { default as rolesr0mEw4PTHVMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/roles.vue?macro=true";
import { default as tax846tKj0iVQMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/tax.vue?macro=true";
import { default as generalreceipt_45_91id_93d2lkhzSlGhMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/generalreceipt-[id].vue?macro=true";
import { default as journal_45_91id_93HpnhlO5lqJMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/journal-[id].vue?macro=true";
import { default as stocktake_45_91id_939ISwGrDf6JMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/stocktake-[id].vue?macro=true";
import { default as transfer_45_91id_93nExM16aIqnMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/transfer-[id].vue?macro=true";
import { default as order_45_91id_93IdCXBgx4dxMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/order-[id].vue?macro=true";
import { default as barcode_45_91id_9368jdQgMf4SMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/products/barcode-[id].vue?macro=true";
import { default as debitnote_45_91id_93dBhtejwNSnMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/debitnote-[id].vue?macro=true";
import { default as purchaseorder_45_91id_93HVe1x9VXS5Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchaseorder-[id].vue?macro=true";
import { default as purchasinginvoice_45_91id_934xFLF3rVsXMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasinginvoice-[id].vue?macro=true";
import { default as purchasingreceipt_45_91id_931bHVoJukTXMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasingreceipt-[id].vue?macro=true";
import { default as accountstatementblZsZeVYCuMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/accountstatement.vue?macro=true";
import { default as balancesheetac1Rka6AVHMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/balancesheet.vue?macro=true";
import { default as currentinventoryqAq4M18IeFMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/currentinventory.vue?macro=true";
import { default as customerstatement7dz4NH8NjZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/customerstatement.vue?macro=true";
import { default as generalreceipts1J9ELfh8AyMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/generalreceipts.vue?macro=true";
import { default as incomestatementeILtsejdwmMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/incomestatement.vue?macro=true";
import { default as inventoryledgert8l7Mx7cqyMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/inventoryledger.vue?macro=true";
import { default as purchasinginvoices3PRiELbpqzMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoices.vue?macro=true";
import { default as purchasinginvoicesagingNuo8xytm88Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoicesaging.vue?macro=true";
import { default as purchasingreceiptslRPrQrG5NtMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasingreceipts.vue?macro=true";
import { default as salesinvoicesdDvZkTyFlDMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoices.vue?macro=true";
import { default as salesinvoicesagingfavHOxX50WMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoicesaging.vue?macro=true";
import { default as salesreceiptsGg6F6WSAO4Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesreceipts.vue?macro=true";
import { default as salestotalbyposnbIKx4gtMYMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbypos.vue?macro=true";
import { default as salestotalbyproductseBnMVlSdBHMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyproducts.vue?macro=true";
import { default as salestotalbystoreaZSCsSZPwTMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbystore.vue?macro=true";
import { default as salestotalbyteammemberCP8ZTJmgNQMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyteammember.vue?macro=true";
import { default as saudivatreportLGMNN83gonMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/saudivatreport.vue?macro=true";
import { default as transactionshistoryEW23nFVN5nMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/transactionshistory.vue?macro=true";
import { default as trialbalancex7hcyaJUpRMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/trialbalance.vue?macro=true";
import { default as vendorstatementUElEs4XxXNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/vendorstatement.vue?macro=true";
import { default as creditnote_45_91id_93WLlvcYRT1rMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/creditnote-[id].vue?macro=true";
import { default as salesinvoice_45_91id_93AnQcrFGOJHMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesinvoice-[id].vue?macro=true";
import { default as salesquotation_45_91id_93m1ygKZbEsSMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesquotation-[id].vue?macro=true";
import { default as salesreceipt_45_91id_93erI0YycNOzMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesreceipt-[id].vue?macro=true";
import { default as printNOPSoVl9iIMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print.vue?macro=true";
import { default as _91debitNoteId_932DLT5x8JhqMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/[debitNoteId].vue?macro=true";
import { default as _91invoiceId_93Z3dh6a7XiPMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/frominvoice/[invoiceId].vue?macro=true";
import { default as indexg9OTBAwB9BMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/index.vue?macro=true";
import { default as _91invoiceId_93hCZ2UXzlhAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/[invoiceId].vue?macro=true";
import { default as indexyp79PQ2JbLMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/index.vue?macro=true";
import { default as newt6tQAaU4sjMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/new.vue?macro=true";
import { default as purchaseorders2myjNbRtxTMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders.vue?macro=true";
import { default as _91receiptId_93kkirFhA1FcMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/[receiptId].vue?macro=true";
import { default as indexN0IXxidqBtMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/index.vue?macro=true";
import { default as settingsqhaXY6fQEAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/settings.vue?macro=true";
import { default as _91vendorId_93GwKIb075LuMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId].vue?macro=true";
import { default as importdiZqTH0b4GMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/import.vue?macro=true";
import { default as indexy6plzSnsBrMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/index.vue?macro=true";
import { default as accountstatementIaR84bwX49Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/accountstatement.vue?macro=true";
import { default as balancesheetRWxfF4brGuMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/balancesheet.vue?macro=true";
import { default as currentinventory3BgH2tuDJMMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/currentinventory.vue?macro=true";
import { default as customerstatementddjs0F3EokMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/customerstatement.vue?macro=true";
import { default as generalreceiptsyhbYyGJr54Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/generalreceipts.vue?macro=true";
import { default as incomestatementUlDUQUpmiXMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/incomestatement.vue?macro=true";
import { default as inventoryledgergyXlLx0S8vMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/inventoryledger.vue?macro=true";
import { default as purchasinginvoicesTaEF3guwSwMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoices.vue?macro=true";
import { default as purchasinginvoicesagingMMBcKIivHyMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoicesaging.vue?macro=true";
import { default as purchasingreceiptsysvoZsDi0HMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasingreceipts.vue?macro=true";
import { default as salesinvoicesbMMBSblExmMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoices.vue?macro=true";
import { default as salesinvoicesagingWKGUTVqNGTMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoicesaging.vue?macro=true";
import { default as salesreceiptstsTxl79u5EMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesreceipts.vue?macro=true";
import { default as salestotalbyposaS4YW0bsrQMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbypos.vue?macro=true";
import { default as salestotalbyproductsIz8tG3DdocMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyproducts.vue?macro=true";
import { default as salestotalbystoreWSyPGaSlUNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbystore.vue?macro=true";
import { default as salestotalbyteammemberPfpqGL4JOAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyteammember.vue?macro=true";
import { default as saudivatreportKtn8iDPsn3Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport.vue?macro=true";
import { default as transactionshistoryFGdLeb4d7DMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/transactionshistory.vue?macro=true";
import { default as trialbalance22Eg1ogX4RMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/trialbalance.vue?macro=true";
import { default as vendorstatementM2Ip9XLYNPMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/vendorstatement.vue?macro=true";
import { default as _91creditNoteId_93jvEFpUEOroMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/[creditNoteId].vue?macro=true";
import { default as _91invoiceId_93kH2GXl0CxNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/frominvoice/[invoiceId].vue?macro=true";
import { default as indexk8t6WweXzbMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/index.vue?macro=true";
import { default as _91customerId_93DakJz6ZxWZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId].vue?macro=true";
import { default as importeQfTsNgBcpMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/import.vue?macro=true";
import { default as index5TpAK86Ye9Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/index.vue?macro=true";
import { default as _91invoiceId_93amfmQhqb2ZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/[invoiceId].vue?macro=true";
import { default as index8FQRdzpv5VMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/index.vue?macro=true";
import { default as newVUWhnepPdLMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/new.vue?macro=true";
import { default as _91receiptId_93U8CJX8pV5IMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/[receiptId].vue?macro=true";
import { default as indexo66vUAdk9DMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/index.vue?macro=true";
import { default as salesquotations3FiUZ681GeMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations.vue?macro=true";
import { default as settingsyxZg2xdbiwMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/settings.vue?macro=true";
import { default as _91orgId_93lGpo7w4kx6Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId].vue?macro=true";
import { default as createbiMY5gPZVOMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/create.vue?macro=true";
import { default as dashboardIzmeksiRZ4Meta } from "/vercel/path0/apps/main/pages/dashboard.vue?macro=true";
import { default as indexOyc1pbfuScMeta } from "/vercel/path0/apps/main/pages/index.vue?macro=true";
import { default as registerM10HqyFLuzMeta } from "/vercel/path0/apps/main/pages/register.vue?macro=true";
export default [
  {
    name: "auth-email-verify-uuid",
    path: "/auth/email/verify/:uuid()",
    component: () => import("/vercel/path0/apps/main/pages/auth/email/verify/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-otp",
    path: "/auth/email/verify/otp",
    component: () => import("/vercel/path0/apps/main/pages/auth/email/verify/otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-email-verify-request",
    path: "/auth/email/verify/request",
    component: () => import("/vercel/path0/apps/main/pages/auth/email/verify/request.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/vercel/path0/apps/main/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    component: () => import("/vercel/path0/apps/main/pages/auth/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset-submit-uuid",
    path: "/auth/password/reset/submit/:uuid()",
    component: () => import("/vercel/path0/apps/main/pages/auth/password/reset/submit/[uuid].vue").then(m => m.default || m)
  },
  {
    name: dashboardIzmeksiRZ4Meta?.name,
    path: "/dashboard",
    component: () => import("/vercel/path0/apps/main/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: accountEhalYsnROyMeta?.name,
    path: "account",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-account-changeemail",
    path: "changeemail",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/changeemail.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account-changepassword",
    path: "changepassword",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/changepassword.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account-editprofile",
    path: "editprofile",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/editprofile.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-account",
    path: "",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-billing-methods-add",
    path: "billing/methods/add",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/methods/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-methods",
    path: "billing/methods",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/methods/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId-addons-add",
    path: "billing/subscriptions/:orgId()/addons/add",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId-addons-remove",
    path: "billing/subscriptions/:orgId()/addons/remove",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/remove.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId-addsubscription",
    path: "billing/subscriptions/:orgId()/addsubscription",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addsubscription.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions-orgId",
    path: "billing/subscriptions/:orgId()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-billing-subscriptions",
    path: "billing/subscriptions",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91orgId_93lGpo7w4kx6Meta?.name,
    path: "organizations/:orgId()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId].vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-organizations-orgId-accounting-accounts-import",
    path: "accounting/accounts/import",
    meta: importGrGX5890kWMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-accounts",
    path: "accounting/accounts",
    meta: indexHuQmqgGIVAMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-dimensions",
    path: "accounting/dimensions",
    meta: dimensionsCDQNiQXkxSMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal-journalId",
    path: "accounting/journal/:journalId()",
    meta: _91journalId_93W7i5aJisM0Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-journal",
    path: "accounting/journal",
    meta: indexO4HWn8H8QKMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-customers",
    path: "accounting/opening/customers",
    meta: customersUF3zhb5mGfMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening",
    path: "accounting/opening",
    meta: indexodMkvReCfsMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-inventory",
    path: "accounting/opening/inventory",
    meta: inventoryYK8wz8fS7pMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/inventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-new",
    path: "accounting/opening/new",
    meta: newCF8k1h3XX8Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-vendors",
    path: "accounting/opening/vendors",
    meta: vendorsyb7olHCgjxMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-opening-yeartoyear",
    path: "accounting/opening/yeartoyear",
    meta: yeartoyearMhBJwPmFkdMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/yeartoyear.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-receipts-receiptId",
    path: "accounting/receipts/:receiptId()",
    meta: _91receiptId_93X3qAhqqDXlMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-receipts",
    path: "accounting/receipts",
    meta: indexYvjYsQO6guMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-accounting-settings",
    path: "accounting/settings",
    meta: settingsBWd8anrbb8Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-assets-assetId",
    path: "fixedassets/assets/:assetId()",
    meta: _91assetId_93fRytpssviQMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-assets",
    path: "fixedassets/assets",
    meta: indexo87uXVvfwAMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-fixedassets-settings",
    path: "fixedassets/settings",
    meta: settings9EPHMAU1iGMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId",
    path: "",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-categories",
    path: "inventory/categories",
    meta: categoriesXQVb4GPE8HMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products-productId",
    path: "inventory/products/:productId()",
    meta: _91productId_93On6Oei9sjpMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products-import",
    path: "inventory/products/import",
    meta: import1jHrIQPsObMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-products",
    path: "inventory/products",
    meta: index8KIo3kzo9XMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-settings",
    path: "inventory/settings",
    meta: settingsrL0nhkcFQiMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stocktakes-stocktakeId",
    path: "inventory/stocktakes/:stocktakeId()",
    meta: _91stocktakeId_93y2GiOxvwSNMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stocktakes",
    path: "inventory/stocktakes",
    meta: indexFvqsAJesBgMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stores-storeId",
    path: "inventory/stores/:storeId()",
    meta: _91storeId_93hBJbUE8oETMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-stores",
    path: "inventory/stores",
    meta: indexuxw4HnIr1QMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-transfers-transferId",
    path: "inventory/transfers/:transferId()",
    meta: _91transferId_93JfOQOHByZ8Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/[transferId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-inventory-transfers",
    path: "inventory/transfers",
    meta: indexwkU20oaki8Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-onboarding",
    path: "onboarding",
    meta: index51dwkgJLL6Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-details",
    path: "org/details",
    meta: detailsR3Gh5ppEEDMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/details.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-lockedperiods",
    path: "org/lockedperiods",
    meta: lockedperiodsVVazmEgMXUMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/lockedperiods.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-members",
    path: "org/members",
    meta: membersq7Ht4lsbEeMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/members.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-pos",
    path: "org/pos",
    meta: indexg5nfL1miGTMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-pos-settings",
    path: "org/pos/settings",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-receiptpaymentmethods",
    path: "org/receiptpaymentmethods",
    meta: receiptpaymentmethodsS4wID02QW8Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/receiptpaymentmethods.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-roles",
    path: "org/roles",
    meta: rolesr0mEw4PTHVMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/roles.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-org-tax",
    path: "org/tax",
    meta: tax846tKj0iVQMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/tax.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print",
    path: "print",
    meta: printNOPSoVl9iIMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-organizations-orgId-print-accounting-generalreceipt-id",
    path: "accounting/generalreceipt-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/generalreceipt-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-accounting-journal-id",
    path: "accounting/journal-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/journal-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-inventory-stocktake-id",
    path: "inventory/stocktake-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/stocktake-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-inventory-transfer-id",
    path: "inventory/transfer-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/transfer-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-order-id",
    path: "order-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/order-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-products-barcode-id",
    path: "products/barcode-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/products/barcode-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchasing-debitnote-id",
    path: "purchasing/debitnote-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/debitnote-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchasing-purchaseorder-id",
    path: "purchasing/purchaseorder-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchaseorder-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchasing-purchasinginvoice-id",
    path: "purchasing/purchasinginvoice-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasinginvoice-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-purchasing-purchasingreceipt-id",
    path: "purchasing/purchasingreceipt-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasingreceipt-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-accountstatement",
    path: "reports/accountstatement",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/accountstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-balancesheet",
    path: "reports/balancesheet",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/balancesheet.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-currentinventory",
    path: "reports/currentinventory",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/currentinventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-customerstatement",
    path: "reports/customerstatement",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/customerstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-generalreceipts",
    path: "reports/generalreceipts",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/generalreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-incomestatement",
    path: "reports/incomestatement",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/incomestatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-inventoryledger",
    path: "reports/inventoryledger",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/inventoryledger.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-purchasinginvoices",
    path: "reports/purchasinginvoices",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-purchasinginvoicesaging",
    path: "reports/purchasinginvoicesaging",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-purchasingreceipts",
    path: "reports/purchasingreceipts",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasingreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salesinvoices",
    path: "reports/salesinvoices",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salesinvoicesaging",
    path: "reports/salesinvoicesaging",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salesreceipts",
    path: "reports/salesreceipts",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbypos",
    path: "reports/salestotalbypos",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbypos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbyproducts",
    path: "reports/salestotalbyproducts",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyproducts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbystore",
    path: "reports/salestotalbystore",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbystore.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-salestotalbyteammember",
    path: "reports/salestotalbyteammember",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salestotalbyteammember.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-saudivatreport",
    path: "reports/saudivatreport",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/saudivatreport.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-transactionshistory",
    path: "reports/transactionshistory",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/transactionshistory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-trialbalance",
    path: "reports/trialbalance",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/trialbalance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-reports-vendorstatement",
    path: "reports/vendorstatement",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/vendorstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-sales-creditnote-id",
    path: "sales/creditnote-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/creditnote-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-sales-salesinvoice-id",
    path: "sales/salesinvoice-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesinvoice-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-sales-salesquotation-id",
    path: "sales/salesquotation-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesquotation-[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-print-sales-salesreceipt-id",
    path: "sales/salesreceipt-:id()",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesreceipt-[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes-debitNoteId",
    path: "purchasing/debitnotes/:debitNoteId()",
    meta: _91debitNoteId_932DLT5x8JhqMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/[debitNoteId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes-frominvoice-invoiceId",
    path: "purchasing/debitnotes/frominvoice/:invoiceId()",
    meta: _91invoiceId_93Z3dh6a7XiPMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/frominvoice/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-debitnotes",
    path: "purchasing/debitnotes",
    meta: indexg9OTBAwB9BMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-invoices-invoiceId",
    path: "purchasing/invoices/:invoiceId()",
    meta: _91invoiceId_93hCZ2UXzlhAMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-invoices",
    path: "purchasing/invoices",
    meta: indexyp79PQ2JbLMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-invoices-new",
    path: "purchasing/invoices/new",
    meta: newt6tQAaU4sjMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-purchaseorders",
    path: "purchasing/purchaseorders",
    meta: purchaseorders2myjNbRtxTMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-receipts-receiptId",
    path: "purchasing/receipts/:receiptId()",
    meta: _91receiptId_93kkirFhA1FcMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-receipts",
    path: "purchasing/receipts",
    meta: indexN0IXxidqBtMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-settings",
    path: "purchasing/settings",
    meta: settingsqhaXY6fQEAMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/settings.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors-vendorId",
    path: "purchasing/vendors/:vendorId()",
    meta: _91vendorId_93GwKIb075LuMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors-import",
    path: "purchasing/vendors/import",
    meta: importdiZqTH0b4GMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-purchasing-vendors",
    path: "purchasing/vendors",
    meta: indexy6plzSnsBrMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-accountstatement",
    path: "reports/accountstatement",
    meta: accountstatementIaR84bwX49Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/accountstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-balancesheet",
    path: "reports/balancesheet",
    meta: balancesheetRWxfF4brGuMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/balancesheet.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-currentinventory",
    path: "reports/currentinventory",
    meta: currentinventory3BgH2tuDJMMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/currentinventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-customerstatement",
    path: "reports/customerstatement",
    meta: customerstatementddjs0F3EokMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/customerstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-generalreceipts",
    path: "reports/generalreceipts",
    meta: generalreceiptsyhbYyGJr54Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/generalreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-incomestatement",
    path: "reports/incomestatement",
    meta: incomestatementUlDUQUpmiXMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/incomestatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-inventoryledger",
    path: "reports/inventoryledger",
    meta: inventoryledgergyXlLx0S8vMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/inventoryledger.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-purchasinginvoices",
    path: "reports/purchasinginvoices",
    meta: purchasinginvoicesTaEF3guwSwMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-purchasinginvoicesaging",
    path: "reports/purchasinginvoicesaging",
    meta: purchasinginvoicesagingMMBcKIivHyMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-purchasingreceipts",
    path: "reports/purchasingreceipts",
    meta: purchasingreceiptsysvoZsDi0HMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasingreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salesinvoices",
    path: "reports/salesinvoices",
    meta: salesinvoicesbMMBSblExmMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoices.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salesinvoicesaging",
    path: "reports/salesinvoicesaging",
    meta: salesinvoicesagingWKGUTVqNGTMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoicesaging.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salesreceipts",
    path: "reports/salesreceipts",
    meta: salesreceiptstsTxl79u5EMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesreceipts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbypos",
    path: "reports/salestotalbypos",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbypos.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbyproducts",
    path: "reports/salestotalbyproducts",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyproducts.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbystore",
    path: "reports/salestotalbystore",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbystore.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-salestotalbyteammember",
    path: "reports/salestotalbyteammember",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salestotalbyteammember.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-saudivatreport",
    path: "reports/saudivatreport",
    meta: saudivatreportKtn8iDPsn3Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-transactionshistory",
    path: "reports/transactionshistory",
    meta: transactionshistoryFGdLeb4d7DMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/transactionshistory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-trialbalance",
    path: "reports/trialbalance",
    meta: trialbalance22Eg1ogX4RMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/trialbalance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-reports-vendorstatement",
    path: "reports/vendorstatement",
    meta: vendorstatementM2Ip9XLYNPMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/vendorstatement.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes-creditNoteId",
    path: "sales/creditnotes/:creditNoteId()",
    meta: _91creditNoteId_93jvEFpUEOroMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/[creditNoteId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes-frominvoice-invoiceId",
    path: "sales/creditnotes/frominvoice/:invoiceId()",
    meta: _91invoiceId_93kH2GXl0CxNMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/frominvoice/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-creditnotes",
    path: "sales/creditnotes",
    meta: indexk8t6WweXzbMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers-customerId",
    path: "sales/customers/:customerId()",
    meta: _91customerId_93DakJz6ZxWZMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers-import",
    path: "sales/customers/import",
    meta: importeQfTsNgBcpMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/import.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-customers",
    path: "sales/customers",
    meta: index5TpAK86Ye9Meta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-invoices-invoiceId",
    path: "sales/invoices/:invoiceId()",
    meta: _91invoiceId_93amfmQhqb2ZMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-invoices",
    path: "sales/invoices",
    meta: index8FQRdzpv5VMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-invoices-new",
    path: "sales/invoices/new",
    meta: newVUWhnepPdLMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-receipts-receiptId",
    path: "sales/receipts/:receiptId()",
    meta: _91receiptId_93U8CJX8pV5IMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-receipts",
    path: "sales/receipts",
    meta: indexo66vUAdk9DMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-salesquotations",
    path: "sales/salesquotations",
    meta: salesquotations3FiUZ681GeMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-organizations-orgId-sales-settings",
    path: "sales/settings",
    meta: settingsyxZg2xdbiwMeta || {},
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-organizations-create",
    path: "organizations/create",
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/vercel/path0/apps/main/pages/register.vue").then(m => m.default || m)
  }
]