const title = ref<string>();

export const usePageTitle = () => {
  const setTitle = (newTitle: string | undefined) => {
    title.value = newTitle;
  };

  return {
    title,
    setTitle,
  };
};
