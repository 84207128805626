import {
  type PermissionRule,
  ACCESS_RULES as ACCESS_RULES_FN,
  checkPermissions,
} from "@mono/constants/lib/accessRules";
import type { PlansFlagsSchema } from "@mono/validation/lib/Plans";
import { useAuthStore } from "@/stores/auth";
import { useOrganizationStore } from "@/stores/organization";

export const useAuthorization = (orgId?: string) => {
  const { getUserOrgPermissions } = useAuthStore();
  const organizationStore = useOrganizationStore();
  const orgIdNormalized = orgId || organizationStore.orgId;

  const orgPermissions = getUserOrgPermissions(orgIdNormalized);

  const checkRole = (role: AccessRole, permissions?: typeof orgPermissions) => {
    const normalizedPermissions = permissions || orgPermissions;
    // if undefined, early return false
    if (normalizedPermissions === undefined) {
      return false;
    }

    const roles = {
      isOwner: normalizedPermissions.isOwner === true,
      isManager: normalizedPermissions.isManager === true,
    };
    return roles[role];
  };

  function checkPermission({ rule }: { rule?: PermissionRule }) {
    // if undefined, early return false
    if (orgPermissions === undefined) {
      return false;
    }

    return rule === undefined || checkPermissions(rule, orgPermissions as any);
  }

  function checkPlanFlag({ planFlag }: { planFlag: keyof PlansFlagsSchema }) {
    // if undefined, early return false
    if (orgPermissions === undefined) {
      return false;
    }

    return orgPermissions[planFlag] === true;
  }

  return {
    checkRole,
    checkPermission,
    checkPlanFlag,
  };
};

export const usePermissionsGuard = ({
  rule,
  planFlag,
}: {
  rule?: PermissionRule;
  planFlag?: keyof PlansFlagsSchema;
}) => {
  const authStore = useAuthStore();
  const organizationStore = useOrganizationStore();
  const isAuthorized = computed(() => {
    const orgPermissions = authStore.getUserOrgPermissions(
      organizationStore.orgId
    );
    // if undefined, early return false
    if (orgPermissions === undefined) {
      return false;
    }

    return (
      (rule === undefined || checkPermissions(rule, orgPermissions as any)) &&
      (planFlag === undefined || orgPermissions[planFlag] === true)
    );
  });

  return { isAuthorized };
};

export const useAccessRules = () => {
  const { t } = useI18n();

  const ACCESS_RULES = computed(() => ACCESS_RULES_FN(t));

  return { ACCESS_RULES };
};
