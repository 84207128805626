<script setup lang="ts">
import { computed, type FunctionalComponent } from "vue";
import { VBtn } from "vuetify/components/VBtn";
import type { RouteLocationRaw } from "vue-router";

interface Props {
  value?: any;
  to?: unknown;
  href?: string;
  color?: string;
  width?: string | number;
  loading?: boolean;
  disabled?: boolean;
  flat?: boolean; // previously `depressed`
  // tile?: boolean; // This prop is no longer available
  variant?: "flat" | "elevated" | "tonal" | "outlined" | "text" | "plain"; // previously `outlined`, `text` but as an option now
  block?: boolean;
  icon?: boolean;
  size?: "x-small" | "small" | "default" | "large" | "x-large";
  exact?: boolean;
  appendIcon?: string | (new () => any) | FunctionalComponent;
  prependIcon?: string | (new () => any) | FunctionalComponent;
  active?: boolean;
  height?: string | number;
  type?: "submit" | "button";
  target?: "_blank";
  density?: "default" | "compact" | "comfortable";
  errorMessage?: string;
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  to: undefined,
  href: undefined,
  color: undefined,
  width: undefined,
  loading: false,
  disabled: undefined,
  flat: true,
  variant: undefined,
  block: undefined,
  icon: false,
  size: "default",
  exact: undefined,
  appendIcon: undefined,
  prependIcon: undefined,
  active: undefined,
  height: undefined,
  type: "button",
  target: undefined,
  download: undefined,
  density: "default",
  errorMessage: undefined,
});

const flatComputed = computed(() => (props.icon ? props.flat : undefined));

const defaultColor = props.icon ? undefined : "primary";
const colorComputed = computed(() =>
  props.color === undefined ? defaultColor : props.color
);
// if type is submit, replace it with button while loading
const typeComputed = computed(() =>
  props.type === "submit" && props.loading ? "button" : props.type
);
</script>

<template>
  <VBtn
    v-bind="{
      ...props,
      flat: flatComputed,
      color: colorComputed,
      to: props.to as RouteLocationRaw,
      disabled: props.disabled || props.loading,
      type: typeComputed,
    }"
  >
    <slot />
  </VBtn>
</template>
