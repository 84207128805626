import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
import BalanceSheetIcon from "@/components/icons/BalanceSheetIcon.vue";
import IncomeStatementIcon from "@/components/icons/IncomeStatementIcon.vue";

const customSvgNameToComponent = {
  BalanceSheetIcon,
  IncomeStatementIcon,
};

const customIcons: IconSet = {
  component: (props: IconProps) =>
    h(
      customSvgNameToComponent[
        props.icon as keyof typeof customSvgNameToComponent
      ]
    ),
};

export { customIcons };
