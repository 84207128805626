import { useAuthStore } from "@/stores/auth";
export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.ruleOrRole) {
    const { permissionRule, accessRole } = to.meta.ruleOrRole;
    const orgId = to.params.orgId.toString();
    const { init } = useAuthStore();
    await init();

    const { checkRole, checkPermission } = useAuthorization(orgId);
    if (accessRole) {
      if (checkRole(accessRole) === false) {
        navigateTo({ name: "dashboard" });
      }
    }
    if (permissionRule) {
      if (checkPermission({ rule: permissionRule }) === false) {
        navigateTo({ name: "dashboard" });
      }
    }
  }
});
