<template>
  <svg
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m391 496c0-24.853-20.147-45-45-45h-180c-24.853 0-45 20.147-45 45v16h270z"
        fill="currentColor"
      />
      <path
        d="m482 121v-30h-183.763c-4.532-12.708-14.529-22.705-27.237-27.237v-63.763h-30v63.763c-12.708 4.532-22.705 14.529-27.237 27.237h-183.763v30h37.504l-67.504 165c0 41.353 33.647 75 75 75h30c41.353 0 75-33.647 75-75l-67.809-165h101.573c4.532 12.708 14.529 22.705 27.237 27.237v214.407c-29.315 5.999-52.505 29.03-58.482 58.356h146.964c-5.977-29.326-29.167-52.357-58.482-58.356v-214.407c12.708-4.532 22.705-14.529 27.237-27.237h101.267l-67.505 165c0 41.353 33.647 75 75 75h30c41.353 0 75-33.647 75-75l-67.809-165zm-339.504 150h-104.992l52.496-126.006zm332 0h-104.992l52.496-126.006z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
