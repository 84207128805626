<script setup lang="ts">
const config = useRuntimeConfig();
const router = useRouter();
const { watchTheme } = useThemeStorage();
const { t, locale } = useI18n();
const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
});

watchTheme();

interface Props {
  error: {
    message: string;
    statusCode: number;
    statusMessage: string;
  };
}

const props = defineProps<Props>();

const robotsMeta =
  config.public.API_TARGET === "production"
    ? []
    : [{ name: "robots", content: "noindex, nofollow" }];

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
    dir: i18nHead.value.htmlAttrs!.dir,
  },
  title: `${props.error.statusCode} - ${t("mezan")}`,
  meta: [
    ...(i18nHead.value.meta || []),
    ...robotsMeta,

    {
      hid: "viewport",
      name: "viewport",
      content: "width=device-width, initial-scale=1",
    },
  ],
  link: [...(i18nHead.value.link || [])],
});

function handleError() {
  if (props.error.statusCode === 404) {
    clearError();
    router.go(-1);
  }
  if (props.error.statusCode === 403) {
    clearError();
    router.push({ name: "index" });
  }
}
</script>

<template>
  <div>
    <Html
      :lang="i18nHead.htmlAttrs?.lang"
      :dir="i18nHead.htmlAttrs?.dir"
      :class="locale === 'en' ? 'font-en' : 'font-ar'"
    >
      <NuxtLayout name="default">
        <header class="py-12 2xl:py-24">
          <div class="mx-auto max-w-3xl px-4">
            <div class="mb-6 text-center">
              <h1 class="mb-2 text-8xl xl:text-[14rem]">
                {{ props.error.statusCode || 500 }}
              </h1>
              <div v-if="props.error.statusCode === 404">
                <p class="mb-3 text-xl">{{ t("we-are-sorry") }}</p>
                <p class="text-2xl font-bold">
                  {{ t("the-page-you-request") }}
                </p>
              </div>
              <div v-if="props.error.statusCode === 403">
                <p class="mb-3 text-xl">{{ t("forbidden") }}</p>
                <p class="text-2xl font-bold">
                  {{ t("you-dont-have-author") }}
                </p>
              </div>
            </div>
            <div class="mb-8">
              <div class="text-center">
                <BtnLink :route="{ name: 'index' }"> {{ t("home") }} </BtnLink>
                <span
                  v-if="props.error.statusCode === 404"
                  class="mx-2"
                />
                <MBtn
                  v-if="props.error.statusCode === 404"
                  @click="handleError"
                >
                  {{ t("back") }}
                </MBtn>
              </div>
            </div>
            <footer>
              <p class="mb-2 text-lg">
                {{ t("if-you-think-this-is") }}
              </p>
              <p class="text-lg">
                {{ t("always-aiming-to-mak") }}
              </p>
            </footer>
          </div>
        </header>
      </NuxtLayout>
    </Html>
  </div>
</template>
