export const CURRENCIES = [
  {
    code: "sar",
    offset: 2,
    displayName: "Saudi Riyal",
    displayNameTranslations: { ar: "ريال سعودي" },
    suffix: "SAR",
    suffixTranslations: { ar: "ر.س" },
    countryId: "",
  },
  {
    code: "usd",
    offset: 2,
    displayName: "US Dollar",
    displayNameTranslations: { ar: "دولار أمريكي" },
    suffix: "USD",
    suffixTranslations: { ar: "د أ" },
  },
  {
    code: "aed",
    offset: 2,
    displayName: "Emirati Dirham",
    displayNameTranslations: { ar: "درهم إماراتي" },
    suffix: "AED",
    suffixTranslations: { ar: "د إ" },
  },
  {
    code: "bhd",
    offset: 3,
    displayName: "Bahraini Dinar",
    displayNameTranslations: { ar: "دينار بحريني" },
    suffix: "BHD",
    suffixTranslations: { ar: "د ب" },
  },
  {
    code: "kwd",
    offset: 3,
    displayName: "Kuwaiti Dinar",
    displayNameTranslations: { ar: "دينار كويتي" },
    suffix: "KWD",
    suffixTranslations: { ar: "د ك" },
  },
  {
    code: "omr",
    offset: 3,
    displayName: "Omani Riyal",
    displayNameTranslations: { ar: "ريال عماني" },
    suffix: "OMR",
    suffixTranslations: { ar: "ر ع" },
  },
  {
    code: "jod",
    offset: 3,
    displayName: "Jordanian Dinar",
    displayNameTranslations: { ar: "دينار أردني" },
    suffix: "JOD",
    suffixTranslations: { ar: "د أ" },
  },
] as const;

export type Currency = Readonly<(typeof CURRENCIES)[number]>;
