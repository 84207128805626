// Event Names convention: category:object_action pattern: account_settings:forget_password_button_click, signup_flow:pricing_page_view
// Property Names convention: object_adjective pattern for property names (e.g., user_id, is_subscribed).
import { type PostHog, posthog, type Properties } from "posthog-js";
import { useRouter } from "nuxt/app";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import { nextTick } from "vue";

let eventTracker: PostHog | undefined;

export default function useAnalytics() {
  const router = useRouter();

  function trackPageView(to: RouteLocationNormalizedLoaded): void {
    const utmSource = to.query.utm_source;
    const utmMedium = to.query.utm_medium;
    const utmCampaign = to.query.utm_campaign;
    const utmContent = to.query.utm_content;
    const utmTerm = to.query.utm_term;
    eventTracker?.capture("$pageview", {
      current_url: to.fullPath,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_term: utmTerm,
    });
  }
  const initPostHog = (publicKey: string): PostHog | undefined => {
    if (!eventTracker) {
      if (import.meta.client) {
        eventTracker = posthog.init(publicKey, {
          api_host: `/analytics/ingest`,
          ui_host: "https://app.posthog.com",
          capture_pageview: false,
          autocapture: true,
          loaded: (posthog: PostHog) => {
            if (typeof localStorage === "undefined") {
              console.error("localStorage is not available.");
              return;
            }
            if (process.env.NODE_ENV === "development") posthog.debug();
          },
        });
      }
    }
    router.afterEach((to: RouteLocationNormalizedLoaded) => {
      nextTick(() => {
        trackPageView(to);
      });
    });
    return eventTracker;
  };

  function track(event: { name: string; properties?: Properties }) {
    if (event.name) {
      eventTracker?.capture(event.name, event?.properties);
    }
  }

  const identifyUser = (
    identifier: string | undefined,
    userProperties?: Properties | undefined
  ) => {
    if (!eventTracker) return;
    (eventTracker as PostHog).identify(identifier, userProperties);
  };
  const posthogInstance = () => {
    return eventTracker as PostHog;
  };

  const getUserDistinctId = () => {
    return eventTracker?.get_distinct_id();
  };

  const resetOnLogout = () => {
    (eventTracker as PostHog)?.reset(true);
  };
  return {
    initPostHog,
    track,
    trackPageView,
    posthogInstance,
    identifyUser,
    getUserDistinctId,
    resetOnLogout,
  };
}
