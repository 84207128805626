import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
import { type I18n, useI18n } from "vue-i18n";
import { customIcons } from "@/components/icons/customIcons";

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: false,
    locale: {
      //! This is a workaround to attach the i18n instance to vuetify
      //! to avoid having two locale states in the app
      //! https://github.com/nuxt-modules/i18n/issues/1831#issuecomment-1413214278
      adapter: createVueI18nAdapter({
        i18n: { global: nuxtApp.$i18n } as I18n,
        useI18n,
      }),
    },
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
        custom: customIcons,
      },
    },
    theme: {
      defaultTheme: "light",
      variations: {
        colors: ["gray"],
        lighten: 9,
        darken: 9,
      },
      themes: {
        light: {
          dark: false,
          colors: {
            primary: "#0A7B34",
            "primary-darken-1": "#096C2E",
            "primary-darken-2": "#085C27",
            "primary-darken-3": "#064D21",
            "primary-darken-4": "#053D1A",
            "primary-darken-5": "#043E1B",
            "primary-darken-6": "#033E1C",
            "primary-darken-7": "#023F1D",
            "primary-darken-8": "#013F1E",
            "primary-darken-9": "#003F1F",
            secondary: "#212121",
            "secondary-darken-1": "#1E1E1E",
            "secondary-darken-2": "#1A1A1A",
            "secondary-darken-3": "#171717",
            "secondary-darken-4": "#131313",
            "secondary-darken-5": "#0F0F0F",
            "secondary-darken-6": "#0C0C0C",
            "secondary-darken-7": "#080808",
            "secondary-darken-8": "#050505",
            "secondary-darken-9": "#010101",
            info: "#03A9F4",
            warning: "#FFC107",
            error: "#E53935",
            success: "#0A7B34",
            gray: "#595959",
          },
        },
        dark: {
          dark: true,
          colors: {
            primary: "#0A7B34",
            "primary-darken-1": "#096C2E",
            "primary-darken-2": "#085C27",
            "primary-darken-3": "#064D21",
            "primary-darken-4": "#053D1A",
            "primary-darken-5": "#043E1B",
            "primary-darken-6": "#033E1C",
            "primary-darken-7": "#023F1D",
            "primary-darken-8": "#013F1E",
            "primary-darken-9": "#003F1F",
            secondary: "#f2f2f2",
            "secondary-darken-1": "#e6e6e6",
            "secondary-darken-2": "#d9d9d9",
            "secondary-darken-3": "#cccccc",
            "secondary-darken-4": "#bfbfbf",
            "secondary-darken-5": "#b3b3b3",
            "secondary-darken-6": "#a6a6a6",
            "secondary-darken-7": "#999999",
            "secondary-darken-8": "#8c8c8c",
            "secondary-darken-9": "#808080",
            info: "#03A9F4",
            warning: "#FFC107",
            error: "#E53935",
            success: "#0A7B34",
            gray: "#bfbfbf",
          },
        },
      },
    },
    display: {
      thresholds: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });

  nuxtApp.vueApp.use(vuetify);
});
