import {
  VueQueryPlugin,
  type VueQueryPluginOptions,
} from "@tanstack/vue-query";

export default defineNuxtPlugin(({ vueApp }) => {
  const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          networkMode: "online",
        },
        mutations: {
          networkMode: "online",
        },
      },
    },
  };

  vueApp.use(VueQueryPlugin, vueQueryPluginOptions);
});
