<template>
  <svg
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m442.214 104.982c-28.567-28.533-68.408-68.43-96.214-96.202v96.216z"
        fill="currentColor"
      />
      <path
        d="m451 134.981-135 .023v-135.004h-255v490.907l127.527-127.014 44.973 44.973 90.866-90.866h-38.366v-30h90v90h-30v-39.208l-112.5 112.5-45.027-45.027-106.143 105.735h368.67zm-194.999 74.756h-120.001v-30h120.001zm0-74.737h-120.001v-30h120.001z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
